.app-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
form#reset-password {
  min-width: 500px;
  border: 1px solid #d9d9d9;
  padding: 20px;
}
#reset-password .ant-form-item-label {
  text-align: start;
}
@font-face {
  font-family: "Soleil";
  font-weight: bold;
  src: local("SoleilBold"),
    url(./assets/fonts/SoleilBold.otf) format("opentype");
}

@font-face {
  font-family: "Soleil";
  src: local("Soleil"), url(./assets/fonts/SoleilRegular.otf) format("opentype");
}

html body {
  background-color: #fbf9f9 !important;
  font-family: "Soleil", serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Soleil', serif !important;
}
code {
  font-family: "Soleil", serif !important;
}

.ant-layout {
  background-color: #fbf9f9 !important;
  min-height: 100vh !important;
}

.ant-btn-primary {
  background: linear-gradient(180deg, #206cf2 0, #004f9e 60%) !important;
  font-weight: 500 !important;
  border: solid 1px transparent !important;
  border-radius: 30px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.nopadding {
  padding: 0!important;margin: 0!important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: linear-gradient(180deg, #206cf2 0, #004f9e 60%)!important;
}


